// core server requests
import { idFlavor } from "../../common";
import {
  CampaignTemplateCustomColumn,
  campaignTemplateMoveDirection,
  CampaignTemplateSpecColumn,
  CampaignTemplateStandardColumn,
  DB_CampaignTemplateSpecColumn,
  DB_CampaignTemplateStandardColumn,
  N_CampaignTemplateColumnColour,
  N_CampaignTemplateData,
} from "./general";
import { specSheetTab, SpecSheetTab } from "../placement";
import { FieldSystemType, N_DictionaryTagColor } from "../infoSchema";
import { z } from "zod";

export interface CampaignTemplateCustomColumnData {
  label: string;
  columnType: CampaignTemplateCustomColumn;
  position: number;
  isActive?: boolean;
  isLimited: boolean | undefined;
  isHidden: boolean;
  color?: N_CampaignTemplateColumnColour;
  characterLimit?: number | null;
  allowTagCreation?: boolean;
  useAsRowColour?: boolean;
  channels: SpecSheetTab[] | undefined;
  width?: number;
  systemType?: FieldSystemType | null;
  dateOffset?: number | null;
  isOffsetAfter?: boolean;
  hasCharacterLimit?: boolean;
  tagOptions?: Record<string, { name: string; color: N_DictionaryTagColor }>;
  offsetColumnId?: string | null;
  positionByTab?: Partial<Record<SpecSheetTab, number>>;
  isHiddenByTab?: Partial<Record<SpecSheetTab, boolean>>;
  mergeCells?: boolean;
  isSticky?: boolean;
  isPrimarySort?: boolean | null;
}

export interface AddCampaignTemplateColumnReq {
  data: CampaignTemplateCustomColumnData;
  campaignId: idFlavor<"Campaign">;
}

export interface CampaignTemplateColumnDataWithFieldId
  extends CampaignTemplateCustomColumnData {
  id: idFlavor<"InfoSchemaField">;
}

export type CampaignTemplateColumnData =
  | {
      kind: "standard";
      column: DB_CampaignTemplateStandardColumn;
    }
  | {
      kind: "spec";
      column: DB_CampaignTemplateSpecColumn;
    }
  | {
      kind: "custom";
      column: CampaignTemplateColumnDataWithFieldId;
    };

export interface UpdateCampaignTemplateColumnReq {
  data: CampaignTemplateColumnData;
  campaignId: idFlavor<"Campaign">;
}

export const moveCampaignTemplateColumnDto = z.object({
  campaignId: z.string(),
  columnId: z.string().or(z.number()),
  tab: z.nativeEnum(specSheetTab).optional(),
  direction: z.nativeEnum(campaignTemplateMoveDirection),
});

export type MoveCampaignTemplateColumnDto = z.infer<
  typeof moveCampaignTemplateColumnDto
>;

export interface CampaignTemplateColumnUpdatesData {
  addColumns: CampaignTemplateCustomColumnData[];
  updateColumns: CampaignTemplateColumnDataWithFieldId[];
  deleteColumns: idFlavor<"InfoSchemaField">[];
}

export interface N_CreateCampaignTemplateWithChildren {
  workspaceId: idFlavor<"Workspace">;
  campaignTemplateData: N_CampaignTemplateData;
  customColumns: CampaignTemplateCustomColumnData[];
  standardColumns: DB_CampaignTemplateStandardColumn[];
  specColumns: DB_CampaignTemplateSpecColumn[];
}

export interface N_UpdateCampaignTemplateWithChildren {
  data: N_CampaignTemplateData;
  customColumnUpdates: CampaignTemplateColumnUpdatesData | undefined;
  standardColumns: DB_CampaignTemplateStandardColumn[];
  specColumns: DB_CampaignTemplateSpecColumn[] | undefined;
}

export interface N_UpdateCampaignTemplateColumns {
  campaignId: idFlavor<"Campaign">;
  customColumnUpdates: CampaignTemplateColumnUpdatesData | undefined;
  standardColumns: DB_CampaignTemplateStandardColumn[];
  specColumns: DB_CampaignTemplateSpecColumn[];
}

export interface N_UpdateCampaignTemplateCustomColumn {
  kind: "custom";
  fieldId: idFlavor<"InfoSchemaField">;
}

export interface N_UpdateCampaignTemplateStandardColumn {
  kind: "standard";
  columnType: CampaignTemplateStandardColumn;
}

export interface N_UpdateCampaignTemplateSpecColumn {
  kind: "spec";
  columnType: CampaignTemplateSpecColumn;
}

type N_UpdateCampaignTemplateColumn =
  | N_UpdateCampaignTemplateCustomColumn
  | N_UpdateCampaignTemplateStandardColumn
  | N_UpdateCampaignTemplateSpecColumn;

export interface UpdateCampaignTemplateColumnWidthReq {
  campaignId: idFlavor<"Campaign">;
  column: N_UpdateCampaignTemplateColumn;
  width: number;
}
