import {
  BookingStandardColumns,
  bookingStandardColumns,
  idFlavor,
  N_Booking,
  placementStandardColumns,
} from "@specsheet-common/shared-types";
import { notNull } from "../typescript";

const getDeliveryInstructionsValue = (booking: N_Booking): string => {
  return [
    booking.dispatchTo ? `Delivery to: ${booking.dispatchTo}` : null,
    booking.dispatchDetails
      ? `Delivery Details: ${booking.dispatchDetails}`
      : null,
  ]
    .filter(notNull)
    .join(", ");
};

const getCreativeInstructionsValue = (booking: N_Booking): string => {
  return [
    booking.materialInstructions?.objectives
      ? `Objective: ${booking.materialInstructions.objectives}`
      : null,
    booking.materialInstructions?.creativeRotation
      ? `Creative: ${booking.materialInstructions.creativeRotation}`
      : null,
    booking.materialInstructions?.targeting
      ? `Targeting: ${booking.materialInstructions.targeting}`
      : null,
    booking.materialInstructions?.messaging
      ? `Messaging: ${booking.materialInstructions.messaging}`
      : null,
    booking.materialInstructions?.notes
      ? `Notes: ${booking.materialInstructions.notes}`
      : null,
  ]
    .filter(notNull)
    .join(", ");
};

export const getStandardColumnValueForBooking = (
  booking: N_Booking
): { [key in BookingStandardColumns]: () => string | null } => {
  return {
    [bookingStandardColumns.deliveryInstructionsColumn]: () =>
      getDeliveryInstructionsValue(booking),
    [bookingStandardColumns.creativeInstructionsColumn]: () =>
      getCreativeInstructionsValue(booking),
    [bookingStandardColumns.notesColumn]: () => booking.notes,
    [bookingStandardColumns.requiredDurationColumn]: () => booking.duration,
    [bookingStandardColumns.dispatchDateColumn]: () => booking.dispatchDate,
    [bookingStandardColumns.endDateColumn]: () => booking.endDate,
    [bookingStandardColumns.liveDateColumn]: () => booking.liveDate,
    [bookingStandardColumns.publicationsColumn]: () => null,
    [bookingStandardColumns.campaignCodeColumn]: () => null,
    [bookingStandardColumns.placementCodeColumn]: () => null,
    [bookingStandardColumns.rowCodeColumn]: () => null,
  };
};

export const forTest = {
  getDeliveryInstructionsValue,
  getCreativeInstructionsValue,
};
